<form *ngIf="segment && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-12 ui-md-6">
                <button pButton type="button" label="{{segment.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)"
                    class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-12 ui-md-6">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-workflow-state-segment-save"></button>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.type" name="type" readonly data-element-id="app-content-forms-workflow-state-segment-type">
                </div>
                <div class="ui-g-6">
                    <label for="label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="segment.label" name="label" placeholder="Required" data-element-id="app-content-forms-workflow-state-segment-label">
                </div>
                <div class="ui-g-6">
                    <label for="transitionType">Transition Type</label>
                    <select type="text" class="form-control" required [(ngModel)]="segment.data.transition_type"
                        name="transition type" data-element-id="app-content-forms-workflow-state-segment-transition-type">
                        <option *ngFor="let transitionType of transitionTypes" [value]="transitionType">
                          {{ transitionType }}
                        </option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <label for="status">Status</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.status" name="status" placeholder="Required" data-element-id="app-content-forms-workflow-state-segment-status">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Identity Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.guid" name="guid" readonly data-element-id="app-content-forms-workflow-state-segment-guid">
                </div>
                <div class="ui-g-6">
                    <label for="origin">Origin</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.origin" name="origin" readonly data-element-id="app-content-forms-workflow-state-segment-origin">
                </div>
                <div class="ui-g-6">
                    <label for="parent">Parent</label>
                    <input type="text" class="form-control" [(ngModel)]="segment.parent" name="parent" readonly data-element-id="app-content-forms-workflow-state-segment-parent-guid">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Advanced Information</span>
            </p-header>
            <div class="ui-g-6">
                <label for="value">Value</label>
                <input type="text" class="form-control" required [(ngModel)]="segment.value" name="value"
                    placeholder="Required" data-element-id="app-content-forms-workflow-state-segment-value">
            </div>
            <div class="ui-g-4">
                <label for="dropdownDisplayName">Dropdown Display Name</label>
                <input type="text" class="form-control" [(ngModel)]="segment.data.dropdown_display_name" name="dropdownDisplayName" data-element-id="app-content-forms-workflow-state-segment-dropdown-display-name">
            </div>
            <div class="ui-g-2">
                <label for="dropdownDisplay">Dropdown Display</label>
                <input type="checkbox" class="form-control" [(ngModel)]="segment.data.dropdown_display" name="DropdownDisplay" style="margin:0 5px 0 0; vertical-align:middle;" data-element-id="app-content-forms-workflow-state-segment-dropdown-display">
            </div>
            <div class="ui-g-6">
                <label for="indicatorIconTypes">Indicator</label>
                <select type="text" class="form-control" [(ngModel)]="segment.data.indicator" name="indicatorIconTypes" data-element-id="app-content-forms-workflow-state-segment-indicator">
                    <option *ngFor="let indicatorIconType of indicatorIconTypes" [value]="indicatorIconType.value">{{ indicatorIconType.label }}</option>
                </select>
            </div>
            <div class="ui-g-6">
                <label for="namespace">Namespace</label>
                <input type="text" class="form-control" [(ngModel)]="segment.namespace" name="namespace" data-element-id="app-content-forms-workflow-state-segment-namespace">
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true"
                        appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="expirationDate" style="display:block;">Expiration Date</label>
                    <p-calendar [(ngModel)]="expirationDate" [showTime]="true" [inline]="false" [showIcon]="true"
                        appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
            </div>
        </p-panel>

    </div>
</form>
