import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Container, Segment } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { SegmentService } from '../../services/segment/segment.service';
import { WorkflowService } from '../../services/workflow/workflow.service';
import { concatMap, tap} from 'rxjs/operators';
import {workflowStateSegmentTransitionTypes} from '../../../shared/enum';
import { iif, of } from 'rxjs';
import { ResourceUploadService } from '../../../upload/resource-upload.service';
import { MessageService } from 'primeng/api';
import { formatError } from '../../../shared/utilities';

@Component({
    selector: 'container-workflow-status',
    providers: [
        ContainerService,
        WorkflowService,
    ],
    templateUrl: 'container-workflow-status.component.html',
    styleUrls: ['container-workflow-status.component.css']
})

export class ContainerWorkflowStatusComponent implements OnInit {
    @Input() container: Container;
    @Input() isContainerLocked: boolean = false;
    @Output() onSimpleStatusChange: EventEmitter < any > = new EventEmitter();
    @Output() onComplexStatusChange: EventEmitter < any > = new EventEmitter();
    @Output() onStatusTransitionFailure:  EventEmitter < any > = new EventEmitter();

    workflowContainer: Container;
    workflowSegments: [];
    selectedSegment:Segment;
    workflowStatusMenuItems: MenuItem[];
    publishDate: Date;

    public newContainerStatus: string = '';
    private newContainerEnabled: boolean = null;
    public isSegmentScheduled: boolean = false;
    public processingRequest = false;
    private validateSchema: boolean = true;
    displayDialog: boolean = false;
    isLoading: boolean = false;

    constructor
        (
        private containerService: ContainerService,
        private segmentService: SegmentService,
        private workflowService: WorkflowService,
        private resourceUploadService: ResourceUploadService,
        private messageService: MessageService,
        ) { }


    ngOnChanges(changes: SimpleChanges) {
      if (changes['container'].previousValue != changes['container'].currentValue && this.container) {
        this.loadWorkflowAndSegmentsContainer();
      }

    }
    ngOnInit() {
        this.loadWorkflowAndSegmentsContainer();
    }

    loadWorkflowAndSegmentsContainer() {
      this.isLoading = true;
      let containerType = this.container.type.name;
      const workflowContainer$ = (origin, subType) => this.containerService.clientSearch(
                              'workflow', 'published', [], origin, 1000, 0, [], [], subType)
      const segmentContainer$ = (guid, origin) => this.segmentService.listByParameters([
                                                  {'parent':guid},
                                                  {'status':[this.container.status]},
                                                  {'origin':origin}])


      workflowContainer$(this.container.origin, containerType).pipe(
        concatMap(res => iif(() => res.count==0, workflowContainer$('system', containerType), of(res))),
        concatMap(res => iif(() => res.count==0, workflowContainer$('system', 'default'), of(res))),
        tap(res => res.count > 0 ? this.workflowContainer = res.results[0] : of(res)),
        // ************************ Load related segments if workflow is found *************************
        concatMap(res => iif(() => Boolean(this.workflowContainer),
            segmentContainer$(this.workflowContainer?.guid, this.workflowContainer?.origin), of(res))),
      ).subscribe(res => {
            this.workflowSegments = res ? (res.count == 0 ? null: res) : null
            if (this.workflowSegments) {this.createMenuItem(this.workflowSegments)}
        },
        err => console.log(err),
        ()  => this.isLoading = false
        )
    }

    createMenuItem(segments) {
      this.workflowStatusMenuItems = []
      segments.forEach(segment => {
        let menuItem;
        if ("dropdown_display" in segment.data && !segment.data.dropdown_display) {
          return; // Skip segment
        }

        if ("schedulable" in segment.data && segment.data.schedulable) {
            menuItem = {label: this.dropdownDisplayOfStatus(segment),
                        automationId:"app-content-components-container-workflow-status-dropdown",
                        icon: segment.data.indicator,
                        command: () => {this.calculateContainerPublishDate();
                                        this.changeStatusTo(segment)}}

        } else {
          // ***** Container status transition menu item *****
            menuItem = {label: this.dropdownDisplayOfStatus(segment),
                        automationId:"app-content-components-container-workflow-status-dropdown",
                        icon: segment.data.indicator,
                        command: () => this.changeStatusTo(segment)}
        }
        this.workflowStatusMenuItems.push(menuItem);
      });
      this.workflowStatusMenuItems.sort((a, b) => a.label.localeCompare(b.label));
    }

    calculateContainerPublishDate() {
      if (this.container.data && this.container.data['available_date']) {
        let now = new Date();
        let availStr = this.container.data['available_date'];
        // Add explicit UTC timezone if unspecified
        if (availStr.length <= 19) { availStr += 'Z'}
        let avail = new Date(availStr);
        this.publishDate = avail > now ? avail : now;
      }
    }

    capitalize(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    dropdownDisplayOfStatus(segment) {
      if ('dropdown_display_name' in segment.data && segment.data.dropdown_display_name) {
          return this.capitalize(segment.data.dropdown_display_name);
      }
      return this.capitalize(segment.value);
    }

    changeStatusTo(segment, is_enabled: boolean = null) {
        // ***** Triggers upon user selects new container status *****
        this.newContainerStatus = segment.value;
        this.newContainerEnabled = is_enabled;
        this.selectedSegment = segment;
        this.isSegmentScheduled = segment.data.schedulable
        this.displayDialog = true;
    }

    executeWorkflowService(prevContainerStatus,prevContainerEnabled) {
      this.workflowService.execute(
        String(this.selectedSegment['data']['transition_type']),
        this.workflowContainer.guid,
        this.container.guid,
        this.selectedSegment['guid'],
        this.publishDate).subscribe(
          res => {
            if (this.selectedSegment['data']['transition_type'] == workflowStateSegmentTransitionTypes[0]) {
              this.container.status = this.newContainerStatus;
              // ***** Emiting signal if selected segment transition_type is simple *****
              //This emit will only happen for on simple transitions Complex will switch to processing then emit
              this.onSimpleStatusChange.emit({
                new_status: this.container.status,
                old_status: prevContainerStatus,
                new_enabled: this.container.is_enabled,
                old_enabled: prevContainerEnabled,
                publish_date: this.container.published_date
              });
            }
            else {
              this.onComplexStatusChange.emit({
                pipeline_triggered: true
              });
            }
          },
          err => {
            console.log(err);
            this.processingRequest = false;
          },
          () => {
            this.processingRequest = false;
            this.loadWorkflowAndSegmentsContainer()
          }
        )
    }
    saveContainerAndPostStatusChangeToService() {
      this.displayDialog = false;
      if(this.hasRequiredFiles(this.selectedSegment)) {
        this.processingRequest = true;
        let prevContainerStatus = this.container.status;
        let prevContainerEnabled = this.container.is_enabled;

        if (this.newContainerEnabled !== null) {
            this.container.is_enabled = this.newContainerEnabled;
        }

        if (this.selectedSegment.data['set_publish_date_now']) {
          this.container.published_date = Math.trunc(Date.now() / 1000);
        } else if (this.selectedSegment.data['schedulable']) {
          this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        } else if (this.selectedSegment.data['set_publish_date_null']) {
          this.container.published_date = null;
        }

        this.validateSchema = this.selectedSegment.data.hasOwnProperty('validate_schema')? this.selectedSegment.data['validate_schema'] : true;

        if ('transition_type' in this.selectedSegment['data']) {
          console.log("Saving Container")
          this.containerService
              .save(this.container, this.validateSchema)
              .subscribe(
                res => {
                  console.log("Container Saved:", res);
                  console.log("Executing Workflow Service");
                  this.executeWorkflowService(prevContainerStatus, prevContainerEnabled)
                },
                err => {
                  console.log(err);
                  let errorMessages = formatError(err, this.messageService, 'wfMsg');
                  console.log(errorMessages);
                  this.onStatusTransitionFailure.emit(errorMessages[1]);
                  this.processingRequest = false;
                },
                () => {
                  this.processingRequest = false;
                }
              );

         }
         else {
          this.onStatusTransitionFailure.emit({
              key: 'wfMsg',
              severity: 'info',
              summary: 'Error',
              detail: `Please select the type of transition in workflow segment`,
          });
         }
      }
    }

  hasRequiredFiles(segment) {
    let requiredFiles = segment.data['required_files'] || [];
    let uploadedFiles = this.container.resources
      .filter(r => r.status == 'new' && r.type.name == 'file')
      .map(r => r.data['source_uri']);
    console.log('Files uploaded:', uploadedFiles);
    let uploadCheck = this.resourceUploadService.checkRequiredFiles(uploadedFiles, requiredFiles);
    if (uploadCheck['missing']['required'].length > 0) {
      let msgs = [];
      msgs.push({ key: 'wfMsg', severity: 'error', summary: 'Error', detail: 'One or more required files missing' });
      for (let spec of uploadCheck['missing']['required']) {
        msgs.push({
          key: 'wfMsg',
          severity: 'info',
          summary: 'Required',
          detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
        });
      }
      this.onStatusTransitionFailure.emit(msgs);
      console.error('Could not perform status transition');
      return false;
    }
    return true;
  }
}
