<div *ngIf="app.isAuthenticated()" class="topbar clearfix">
    <p-confirmDialog #cd header="Logout" icon="pi pi-exclamation-triangle">
        <p-footer style="border-top:O none;">
            <button type="button" pButton 
                    label="Logout"
                    class="p-button-danger"
                    (click)="cd.accept()"
                    data-element-id="app-user-logout-confirm">
            </button>
        </p-footer>
    </p-confirmDialog>
    <a href="#" id="menu-button" (click)="app.onMenuButtonClick($event,0)">
        <span class="fa fa-angle-left"></span>
    </a>
    <a class="morpheus-logo-link" href="#">
        <img src="assets/layout/images/vms-logo-green.png" class="morpheus-logo" />
        <img src="{{appContext.activeOriginObject['logo_url'] || ''}}" class="morpheus-logo" />
        <span class="morpheus-logo-text">
        </span>
    </a>

    <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
        <i class="fa fa-bars"></i>
    </a>
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
            <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === profile}">
                <a href="#" (click)="app.onTopbarItemClick($event,profile)" data-element-id="app-user-menu-profile">
                    <i class="topbar-icon animated swing fa fa-fw fa-user-circle" *ngIf="appContext.getProfile().data === null || !appContext.getProfile().data['image_url'] || appContext.getProfile().id === -1"></i>
                    <div *ngxPermissionsOnly="['view.show.beta']">
                        <p-avatar *ngIf="appContext.getProfile().data !== null && appContext.getProfile().id !== -1" [image]= "appContext.getProfile().data['image_url']" styleClass="p-mr-2" shape="circle"></p-avatar>
                    </div>
                    <span class="profile-text">Profile</span>
                </a>

                <ul class="fadeInDown">
                    <li role="menuitem" [routerLinkActive]="['active']" *ngxPermissionsOnly="['view.show.beta']">
                        <account-profile-edit *ngIf="showProfile" [showProfile]="showProfile" (closeProfile)="showProfile=false"></account-profile-edit>
                        <a (click)="openProfile()" class="nav-link" data-element-id="app-user-menu-show-profile">
                            <i class="fa fa-address-card-o"></i>
                            <span> Profile</span>
                        </a>
                    </li>
                    <li role="menuitem" [routerLinkActive]="['active']">
                        <a (click)="changePassword()" class="nav-link">
                            <i class="fa fa-fw fa-lock"></i>
                            <span>Change Password</span>
                        </a>
                    </li>
                    <li role="menuitem" [routerLinkActive]="['active']">
                        <a (click)="logoutConfirm()" class="nav-link" data-element-id="app-user-menu-logout">
                            <i class="fa fa-fw fa-sign-out"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>

    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
        <li [ngStyle]="{'justify-content': 'center', 'display':'flex'}"  *ngxPermissionsOnly="['view.show.beta']">
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="onSearch($event)"></i>
                <input type="text" pInputText placeholder="Search" [(ngModel)]="searchQuery"  (keydown.enter)="onSearch($event)"/>
            </span>
        </li>
       
        <li #origin class="profile-item" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === origin}">
            <a href="#" (click)="app.onTopbarItemClick($event,origin)"data-element-id="app-origin">
                <i class="topbar-icon animated swing fa fa-fw fa-codepen"></i>
                <span class="profile-text">Origin</span>
            </a>
            <ul class="fadeInDown"  [ngStyle]="{'width': getMenuItemLayoutWidth()}">
                    <li role="menuitem"  [ngClass]="getMenuItemLayoutClass()" *ngFor="let origin of appContext.authorizedOrigins" [routerLinkActive]="['active']">
                            <a class="origin-text" href="#" (click)="appContext.onSelectNewOriginClick($event,origin)">
                                <i class="fa fa-fw fa-caret-right"></i>
                                <span *ngIf="origin != appContext.activeOrigin">
                                    {{appContext.getOriginLabel(origin)}}
                                </span>

                                <span *ngIf="origin == appContext.activeOrigin">
                                    <b>{{appContext.getOriginLabel(origin)}}</b>
                                </span>
                            </a>
                    </li>
            </ul>
        </li>
    </ul>

    <ul class="topbar-menu fadeInDown" *ngIf="resourceUploadService.getResourceUploadContextIdList().length > 0" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
            <li #upload class="profile-item" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === upload}">
                <a href="#" (click)="app.onTopbarItemClick($event,upload)">
                    <i class="topbar-icon animated swing fa fa-fw fa-upload"></i>
                    <span class="topbar-badge animated rubberBand" *ngIf="resourceUploadService.getNumberOfUploadingContexts() > 0">{{resourceUploadService.getNumberOfUploadingContexts()}}</span>
                    <span class="topbar-badge-error animated rubberBand" *ngIf="resourceUploadService.hasUploadError() === true"><b>!</b></span>
                    <span class="profile-text">Uploads</span>
                </a>
                <ul class="fadeInDown">
                        <li role="menuitem" *ngFor="let id of resourceUploadService.getResourceUploadContextIdList()" [routerLinkActive]="['active']">
                                    <i class="fa fa-fw fa-caret-right"></i>
                                    <span >
                                        <label for="uploading">{{resourceUploadService.getUploadingFilesForContainerId(id).container.data['title']}} - {{resourceUploadService.getUploadingFilesForContainerId(id).status}} - {{resourceUploadService.getUploadingFilesForContainerId(id).files_uploaded.length}}/{{resourceUploadService.getUploadingFilesForContainerId(id).files.length}} files</label>
                                        <button type="button" class="utility-button" icon="fa fa-pencil"  [routerLink]="['/workflow/worklist', resourceUploadService.getUploadingFilesForContainerId(id).container.guid]"><i class="fa fa-pencil"></i></button>

                                        <div class="ui-g-12" *ngIf="resourceUploadService.getUploadingFilesForContainerId(id).status === 'uploading'">
                                            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
                                        </div>
                                    </span>
                        </li>
                </ul>
            </li>
    </ul>
</div>
