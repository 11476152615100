import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../models';
import { MenuItem } from 'primeng/api';

import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { ContainerService } from '../../services/container/container.service';


@Component({
    selector: 'container-add-templated',
    templateUrl: './container-add-templated.component.html',
    styleUrls: ['./container-add-templated.component.css']
})
export class ContainerAddTemplatedComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() defaultRelateType: any;
    @Input() profileTemplateNamespace: any;

    @Output() onComplete: EventEmitter<any> = new EventEmitter();
    @Output() onError: EventEmitter<any> = new EventEmitter();

    private container: Container;
    private templateProfiles = [];
    private contentTemplates = [];
    private entityType;

    public containerAddTypes: MenuItem[] = [];
    public defaultLabel: string = 'Add';
    public isLoading = false;
    public selectedTemplate: string;


    constructor(
        private entityTypeService: EntityTypeService,
        private containerService: ContainerService,
        private router: Router
    ) { }

    ngOnInit() {
        this.getTemplateProfiles()
    }

    getTemplateProfiles() {
        this.isLoading = true;
        this.containerService
            .clientSearch('profile', 'published', ['label'])
            .subscribe(
                res => {
                    this.templateProfiles = (res.results.filter(t => ((t.data.profile_type === 'container') && (t.data.bundle_id === this.profileTemplateNamespace))));
                    for (let template of this.templateProfiles) {
                        let templateValue = { label: template['data']['name'], value: template['data']['bundle_id'] };
                        this.contentTemplates.push(templateValue);

                        this.containerAddTypes.push({
                            label: template['data']['name'],
                            icon: 'pi pi-plus',
                            automationId: 'app-content-components-container-add-templated-collections-dropdown',
                            command: () => {
                                this.onTemplateChange(template.id);
                            }
                        })
                    }
                },
                err => {
                    console.log(err);
                    this.onError.emit(err)
                },
                () => this.isLoading = false
            );
    }

    onTemplateChange(id) {

        this.container = new Container();

        let selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(id, 10));

        let containerJSON = selectedTemplateContainer.data.payload;

        if (typeof containerJSON == 'string') {
            containerJSON = JSON.parse(containerJSON);
        }

        if ('type' in containerJSON.container) {
            this.getEntityType(containerJSON.container.type);
        }

        if ('status' in containerJSON.container) {
            this.container.status = containerJSON.container.status;
        }

        this.container = Object.assign(this.container, containerJSON.container);

        this.container.data['template_profile_guid'] = selectedTemplateContainer.guid;

        this.container.data['primary_parent_container_id'] = this.parentContainer.guid;

        if ('default_tags' in containerJSON) {
            this.container.tags = containerJSON['default_tags'];
        }

        if ('filter_media_by_parent_id' in containerJSON.container.data) {
          let parentId = containerJSON.container.data['filter_media_by_parent_id']
          parentId.replace(/\{(\w+)\}/, (_, match) => {
            if (containerJSON.container.data[match]) {
              this.container.data['filter_media_by_parent_id'] = containerJSON.container.data[match]
            } else {
              this.onError.emit({
                status: `${match}`,
                statusText: `missing in ${selectedTemplateContainer.data.name} template metadata`
              })
            }
          });
        }

    }

    createContainer(container) {
        this.containerService
            .save(container)
            .subscribe(
                res => {
                    console.log(res);
                    this.container = Object.assign(new Container(), this.container);
                    this.relateContainer(this.container)
                },
                err => {
                    console.log(err)
                    this.onError.emit(err)
                },
                () => { }
            );
    }

    relateContainer(createContainer) {
        this.containerService
            .relateById(this.parentContainer.id, createContainer.id)
            .subscribe(
                res => {
                    this.onComplete.emit()
                },
                err => {
                    console.log(err)
                    this.onError.emit(err)
                }
            );
    }

    getEntityType(entityTypeName) {
        this.isLoading = true;
        this.entityTypeService
            .getByName(entityTypeName)
            .subscribe(
                (res: any) => {
                    this.entityType = res;
                    this.container.type = this.entityType;

                    console.log(res);
                    this.createContainer(this.container)
                },
                err => {
                    console.log(err)
                    this.onError.emit(err)
                },
                () => this.isLoading = false
            );
    }

    defaultAction() {
        return this.router.navigate(['/containers', this.parentContainer.id, 'relate', this.defaultRelateType]);
    }
}
