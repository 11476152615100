<div class="card card-w-title top-level-card">
    <h1>Edit Collection{{!isLoading && container ? ': ' + container.data['title'] : ''}}</h1>

    <page-header title="Edit Collection{{!isLoading && container ? ': ' + container.data['title'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <collection-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)" (statusError)="onStatusError($event)" (pipelineTriggered)="onPipelineTriggered($event)"></collection-form>

        <hr>
        <div class="ui-g-12">
            <p-panel>
                <p-header>
                    <div class="ui-g">
                        <div class="p-panel-title ui-g-12 ui-md-3">
                            <span class="view-title">Collection Items <p-badge value='{{ containerCount }}' styleClass="p-mr-2" severity="info"></p-badge>
                            </span>
                        </div>
                        <!-- <div class="ui-g-12 ui-md-9"> -->

                            <div class="ui-g-12 ui-md-9 ui-lg-9 ui-g-nopad">
                                <container-add [container]="container" [defaultAddAction]="defaultAddAction" [addActionsList]="addActionsList" style="float:right;" data-element-id="app-content-components-collection-edit-media-dropdownlist"></container-add>
                                <!-- <button pButton type="button" label="Collection" [routerLink]="['/containers', container?.id, 'relate', 'collection']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Dynamic" [routerLink]="['/containers', container?.id, 'relate', 'dynamic']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Channel" [routerLink]="['/containers', container?.id, 'relate', 'channel']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Series" [routerLink]="['/containers', container?.id, 'relate', 'series']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Media" [routerLink]="['/containers', container?.id, 'relate', 'media']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Movie" [routerLink]="['/containers', container?.id, 'relate', 'movie']" icon="pi pi-plus"></button>
                                <button pButton type="button" label="Episode" [routerLink]="['/containers', container?.id, 'relate', 'episode']" icon="pi pi-plus"></button> -->
                            </div>
                        <!-- </div> -->
                        <spinner *ngIf="isReordering" class="ui-g-12"></spinner>
                    </div>
                </p-header>
                <container-paginated-datarow type="all"
                                itemsPerPage = 20
                                [parentContainer]="container"
                                filterBy="data.title, data.keywords"
                                [sortOptions]="episodeSortOptions"
                                (reorder)="onReorder($event)"
                                class="nested-panel"
                                [ngStyle]="{'border': '0 none'}"
                                [badgeVisible]="False"
                                (countUpdatedEvent)="updateCount($event)"
                                data-element-id="app-content-components-collection-edit-collection-datarow">
                </container-paginated-datarow>
            </p-panel>
        </div>
        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions" data-element-id="app-content-components-collection-edit-resources-datarow">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload"  *ngxPermissionsOnly="['view.show.upload']" data-element-id="app-content-components-collection-edit-upload"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource', 'upload']" *ngxPermissionsOnly="['object.write.all', 'object.write.collection']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button *ngxPermissionsOnly="['view.show.curate']" pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;" data-element-id="app-content-components-collection-edit-create"></button>
            </resource-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
          <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
