<p-dialog header="Relationship Tags"
    [(visible)]="showTagDialog"
    [modal]="true"
    [responsive]="true"
    [baseZIndex]="10000"
    [focusOnShow]="false"
    [appendTo]="'body'"
    (onHide)="hideForm()"
    [style]="{'min-width':'410px', 'max-width':'410px'}"
    >

    <div *ngIf="!isLoadingTags" class="ui-g-12 ui-md-12 ui-lg-12" style="min-height:250px">
      <div class="ui-g-12">
      <button pButton label="Save" icon="pi pi-check" style="position:absolute;top:45px;right:20px;z-index:1;" (click)="onSaveTags()" ></button>
      </div>
        <div *ngFor="let tagType of tagsTypes" style="width:auto;display:block;margin-bottom:1em;">
            <label for="tagType">{{tagType | labelToDisplay}}</label>
            <span class="p-float-label">
                <p-multiSelect [options]="tagsDict[tagType]"
                                [(ngModel)]="selectedTagsDict[tagType]"
                                name="tagType"
                                optionLabel="label"
                                maxSelectedLabels=100
                                [defaultLabel]="'Choose'"
                                [style]="{'min-width':'100%', 'max-width':'100%'}">
                    <ng-template let-tag pTemplate="item">
                        <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                    </ng-template>
                </p-multiSelect>
            </span>
        </div>
    </div>
    <p-progressSpinner *ngIf="isLoadingTags == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</p-dialog>
