import { AuthService } from './auth/auth.service';
import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import '../../node_modules/primeng/resources/primeng.min.css';
import { UXAnalyticsService } from './ux-analytics/ux-analytics.service';

enum MenuOrientation {
    STATIC,
    OVERLAY
}

@Component({
    selector: 'app',
    providers: [
        // AuthService
    ],
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})
export class AppComponent {

    activeTabIndex: number = -1;

    sidebarActive = false;

    layoutMode: MenuOrientation = MenuOrientation.OVERLAY;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    rotateMenuButton: boolean;

    sidebarClick: boolean;

    topbarItemClick: boolean;

    menuButtonClick: boolean;

    activeTopbarItem: any;

    documentClickListener: Function;

    theme = 'green';

    constructor(
        private authService: AuthService,
        public renderer: Renderer2,
        private router: Router,
        private UXAnalyticsService: UXAnalyticsService
    ) {
        this.router.events.subscribe((e: any) => {
            // console.log('Router event:', e);
        });

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
        };

        this.UXAnalyticsService.startTrackingPathChanges();

    }

    isAuthenticated() {
        return this.authService.isAuthenticated();
    }

    ngAfterViewInit() {
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuButtonClick && !this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }

            this.topbarItemClick = false;
            this.sidebarClick = false;
            this.menuButtonClick = false;
        });
    }

    onTabClick(event: Event, index: number) {
        if (this.activeTabIndex === index) {
            this.sidebarActive = !this.sidebarActive;
        } else {
            this.activeTabIndex = index;
            this.sidebarActive = true;
        }
        // Stop the sidebar expansion when the home menu tab is selected
        if (this.activeTabIndex === 0 || this.activeTabIndex === 8) {
            this.sidebarActive = false;
        }
        event.preventDefault();
    }

    closeSidebar(event: Event) {
        this.sidebarActive = false;
        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.sidebarClick = true;
    }

    onTopbarMenuButtonClick(event: Event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
    }

    onMenuButtonClick(event: Event, index: number) {
        this.menuButtonClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
        this.sidebarActive = !this.sidebarActive;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }
        // Stop the sidebar expansion when the top menu (>) is selected
        if (this.activeTabIndex <= 0) {
            this.sidebarActive = false;
        }

        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarSearchItemClick(event: Event) {
        this.topbarItemClick = true;

        event.preventDefault();
    }

    get overlay(): boolean {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
        this.UXAnalyticsService.stopTrackingPathChanges()
    }
}
