<p-panel>
    <p-header>
        <div class="ui-g">
            <span *ngIf="badgeVisible" class="p-panel-title ui-g-12 ui-md-2 ui-lg-3 view-title" style="padding:0;text-align:left">
                {{title}} <p-badge value='{{ containerCount }}' styleClass="p-mr-2" severity="info"></p-badge>
            </span>
            <div class="ui-g-12 ui-md-2 ui-lg-3" style="padding:0;">
                <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="onFilter($event.target.value)" class="filter-bar"/>
            </div>
            <div class="ui-g-12 ui-md-5 ui-lg-3 sort-status-dropdown" style="padding:0; display:flex;">
                <p-dropdown [options]="orderingOptions" [(ngModel)]="selectedOrderingOption"  (ngModelChange)="onOrderingChange()"  appendTo="body"></p-dropdown>
                <p-dropdown [options]="statusList" placeholder="No Status Filter" [(ngModel)]="selectedStatusOption"  (ngModelChange)="onStatusChange()"  appendTo="body" [style]="{'margin-left': '7px'}" data-element-id="app-content-components-container-paginated-datarow-status-dropdown"></p-dropdown>
            </div>
            <div class="ui-g-12 ui-md-3 ui-lg-3" style="padding:0;">
                <p-progressSpinner *ngIf="loadingNextPage" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <ng-content *ngIf="!loadingNextPage"></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <div *ngIf="isLoading">
        <div class="progress">
            <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                style="width: 100%">
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading" class="items" detect-scroll (scroll)="this.onScroll($event)" [dragula]="name" [(dragulaModel)]="allContainers" data-element-id='app-content-components-container-paginated-datarow-containers'>
        <div *ngFor="let container of shownContainers">
            <container *ngIf="container.show" class="item" [container]="container" [parentContainer]="parentContainer" (onRemove)="containerRemoved($event)"></container>
        </div>
    </div>
</p-panel>
