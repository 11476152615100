<spinner *ngIf="isLoading"></spinner>
<form *ngIf="resource && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        <p-toast key="csMsg"></p-toast>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{resource.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left"
                    style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-2 ui-g-offset-8">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-content-forms-resource-form-save"></button>
            </div>
        </p-toolbar>
        <div class="ui-g-8">
            <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
                </p-header>

                <div style="padding:0.5em;overflow:hidden;">
                    <div class="ui-g-3">
                        <label for="resourceId">Id</label>
                        <input type="text" class="form-control" [(ngModel)]="resource.id" name="resourceId" placeholder="Required" [readonly]="true" data-element-id="app-content-forms-resource-form-id">
                    </div>
                    <div class="ui-g-3">
                        <label for="type">Type</label>
                        <input type="text" class="form-control" [(ngModel)]="entityTypeName" name="type" [disabled]="true" *ngIf="fixedType">
                        <select required [(ngModel)]="entityTypeId" (ngModelChange)="onTypeChange()" name="type" class="form-control" *ngIf="!fixedType" data-element-id="app-content-forms-resource-form-type">
                            <option *ngFor="let entityType of entityTypes" [value]="entityType.id">
                                {{ entityType.name }}
                            </option>
                        </select>
                    </div>
                    <div class="ui-g-3">
                        <label for="resourceStatus">Status</label>
                        <select required [(ngModel)]="resource.status" name="resourceStatus" class="form-control" [disabled]="fileSource" data-element-id="app-content-forms-resource-form-status">
                            <option *ngFor="let status of statusList" [value]="status">
                                {{ status | titlecase }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="!isLoadingTags" class="ui-g-3">
                        <label for="tags">Tags</label>
                        <span class="p-float-label" data-element-id="app-content-forms-resource-form-tags">
                            <p-multiSelect [options]="tags" 
                                            [(ngModel)]="selectedTags" 
                                            name="tags" 
                                            optionLabel="value"
                                            [defaultLabel]="'Choose Tag(s)'"
                                            appendTo="body"
                                            [style]="{'width':'100%'}">
                                <ng-template let-tag pTemplate="item">
                                    <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                                </ng-template>
                            </p-multiSelect>
                        </span>
                    </div>

                    <div *ngIf="resource.id > 0" class="ui-g-12">
                        <history [inputObject]="resource" inputType='resource' title="History">
                        </history>
                    </div>
                </div>
            </p-panel>
            <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
                </p-header>

                <div style="padding:0.5em;overflow:hidden;">
                    <div class="ui-g-4">
                        <label for="resourceGuid">GUID</label>
                        <input type="text" class="form-control" [(ngModel)]="resource.guid" name="resourceGuid" placeholder="Required" *ngIf="createFormType && resource.id < 0" data-element-id="app-content-forms-resource-form-guid">
                        <input type="text" class="form-control" [(ngModel)]="resource.guid" name="resourceGuid" *ngIf="!createFormType && resource.id < 0" data-element-id="app-content-forms-resource-form-guid">
                        <input type="text" class="form-control" [(ngModel)]="resource.guid" name="resourceGuid" [disabled]="true" *ngIf="resource.id >= 0" data-element-id="app-content-forms-resource-form-guid">

                    </div>

                    <div class="ui-g-4">
                        <label for="resourceNamespace">Namespace</label>
                        <input type="text" required class="form-control" [(ngModel)]="resource.namespace" name="resourceNamespace">
                    </div>

                    <div class="ui-g-4">
                        <label for="resourceSubtype">Subtype</label>
                        <input type="text" required class="form-control" [(ngModel)]="resource.subtype" name="resourceSubtype">
                    </div>
                </div>
            </p-panel>
            <p-panel *ngIf="!noUploadTypes" class="ui-g-12">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Upload Image </span>
                </p-header>

                <div class="ui-g-12">
                    <label for="tags">(Deprecated - will change in future)</label>
                    <span class="p-float-label">
                        <uploader (uploadComplete)="onUploadComplete($event)"></uploader>
                    </span>
                </div>

            </p-panel>
        </div>
        <div *ngIf="displayPreview || resource.type.name == 'video' || resource.type.name == 'audio'" class="ui-g-4">
            <p-panel  class="ui-g-12">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Preview</span>
                </p-header>

                <div class="preview" *ngIf="displayPreview">
                    <img [src]="resource.uri" class="preview__image">
                </div>

                <div *ngIf="resource.type.name == 'video' || resource.type.name == 'audio'">
                     <preview-player [resource]="resource" [playerStyle]="{'height': '220'}" trackPlayerState="false"></preview-player>
                </div>

            </p-panel>
        </div>


        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Asset Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <!-- <div *ngIf="noUploadTypes" class="ui-g-12">
                    <label for="uri">URI</label>
                    <input type="text" required class="form-control" [(ngModel)]="resource.uri" name="uri" [disabled]="true" placeholder="Required">
                </div> -->
                <div class="ui-g-12">
                    <label for="uri">URI</label>
                    <input type="text" required class="form-control" [(ngModel)]="resource.uri" name="uri" placeholder="Required" data-element-id="app-content-forms-resource-form-uri">
                </div>
                <div *ngIf="!noUploadTypes" class="ui-g-12">
                    <label for="file_source">Source File</label>
                    <select [(ngModel)]="fileSourceId" (ngModelChange)="onFileChange()" name="file_source" class="form-control" [disabled]="fixedFileSource">
                        <option value=""> -- </option>
                        <option *ngFor="let fileRes of fileResources" [value]="fileRes.id">
                            {{ fileRes.data['source_uri'] }}
                            <!-- {{ fileRes.data['source_uri'] | fileName }} -->
                        </option>
                    </select>
                    <!-- <input type="text" required class="form-control" [(ngModel)]="resource.data['source_uri']" name="source_uri"> -->
                </div>
                <!--
                <div class="ui-g-12">
                    <uploader (uploadComplete)="onUploadComplete($event)"></uploader>
                </div>
                -->
            </div>
        </p-panel>
        <p-panel *ngIf="resource.type.name == 'credential'" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Credentials and Security</span>
            </p-header>


            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="credential_type">Credential Type</label>
                    <select [(ngModel)]="resource.data.credential_type" name="credentialtype" class="form-control">
                        <option *ngFor="let credentialType of credentialTypes">{{ credentialType }}</option>
                    </select>
                </div>
                <div class="ui-g-4">
                    <label for="username">Username</label>
                    <input type="text" required class="form-control" [(ngModel)]="resource.data.username" name="username">
                </div>
                <div class="ui-g-4">
                    <label for="password">Password</label>
                    <input type="text" required class="form-control" [(ngModel)]="resource.data.password" name="password">
                </div>
                <!--
                <div class="ui-g-12">
                    <uploader (uploadComplete)="onUploadComplete($event)"></uploader>
                </div>
                -->
            </div>
        </p-panel>


    </div>
</form>
