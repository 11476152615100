import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ContainerService } from '../../services/container/container.service';
import { ActivatedRoute } from '@angular/router';
import { Container } from '../../models/container';
import { TreeNode } from 'primeng/api';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'container-bulk-update',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-bulk-update.component.html',
    styleUrls: ['container-bulk-update.component.css']
})
export class ContainerBulkUpdateComponent implements OnInit {
    public containers = [];
    public isLoading = false;
    private error: string;
    public sortField: string;
    public sortOrder: number;
    private status: string = 'published';
    public seasons = [];
    public episodes = [];
    public episodeContainers: Container[] = [];

    public selectedEpisodes: Container[] = [];

    private sub: any;
    public container: Container;
    public seasonContainers: any;
    public seasonData: any;

    public isSeasonLoading;
    public loading;
    private id: any;

    public selectedContainers: TreeNode[];
    public displayPublicWindowEndDateDialog: Boolean = false;

    actionMenuItems: MenuItem[];

    cols: any[];

    publishDate: Date;
    publicWindowEndDate: Date;

    public processing = false;
    public progress = 0;

    public data =
        []
    public c;

    constructor(
        private containerService: ContainerService,
        private route: ActivatedRoute,
        private location: Location
    ) { }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.id = +params['id']; 
            console.log('id', this.id);
            this.loadContainerData(this.id);
        });

        this.sortField = 'label';
        this.sortOrder = 1;
        this.loadPage();

        this.cols = [
            { field: 'label', header: 'Seasons' },
            { field: 'status', header: 'Status' },
            { field: 'guid', header: 'GUID' },
            { field: 'number', header: 'Number' }
        ];

        this.createMenuItems();
    }

    loadPage() {
        this.isLoading = true;
        this.containerService
            .clientSearch('series', this.status, [this.sortField])
            .subscribe(
                res => {
                    console.log(res.results);
                    this.containers = res.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.containerService
            .get(id)
            .subscribe(
                res => {
                    this.container = res;
                    this.containerService
                        .getRelatedByTypeAndGuidPaginated('season', this.container.guid, 1, 200, 'to_container__order,-created_date', '', 'published')
                        .subscribe(
                            res => {
                                this.seasonContainers = res;
                                this.data = this.processContainer(this.seasonContainers)
                            },
                            err => {
                                console.log(err);
                                this.error = err.statusText;
                            },
                            () => { }
                        );

                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    onNodeExpand(event) {
        this.loading = true;
        this.containerService
            .getRelatedByTypeAndGuidPaginated('episode', event.node.data.guid, 1, 250)
            .subscribe(
                res => {
                    this.processEpisodeContainer(res, event.node)
                    this.data = [...this.data]
                },
                err => {
                    this.error = err;
                    console.error('Error fetching episodes:', err);
                }
            );

    }

    getEpisodeData(seriesGUID) {
        this.containerService
            .getRelatedByTypeAndGuidPaginated('season', seriesGUID, 1, 100)
            .subscribe(
                res => {
                    console.log("season list", res);
                    this.seasons = res;
                    this.isSeasonLoading = false;
                },
                err => {
                    this.error = err;
                    console.error('Error fetching seasons:', err);
                    this.isSeasonLoading = false;
                }
            );
    }

    getSeasonsData(seriesGUID) {
        this.containerService
            .getRelatedByTypeAndGuidPaginated('season', seriesGUID, 1, 100)
            .subscribe(
                res => {
                    this.seasons = res;
                    this.isSeasonLoading = false;
                },
                err => {
                    this.error = err;
                    this.isSeasonLoading = false;
                }
            );
    }

    processContainer(seasons) {
        let seasonData = []
        seasons.forEach(season => {
            let seasonTreeNode: any;
            seasonTreeNode = {
                data:
                {
                    label: season.data.title,
                    guid: season.guid,
                    number: season.data.season_number,
                    status: season.status,
                    type: 'season',
                    loading: true
                }
            },
            this.containerService
                    .getRelatedByTypeAndGuidPaginated('episode', season.guid, 1, 100)
                    .subscribe(
                        res => {
                            this.processEpisodeContainer(res, seasonTreeNode)
                            seasonTreeNode.data['loading'] = false;
                        },
                        err => {
                            this.error = err;
                            console.error('Error getting episodes:', err);
                        }
                    );
            seasonData.push(seasonTreeNode)
        });
        return seasonData;
    }

    processEpisodeContainer(episodes, seasonTreeNode) {
        let episodesData = []
        episodes.forEach(episode => {
            let episodeTreeNode: any;
            episodeTreeNode = {
                data:
                {
                    label: episode.data.title,
                    guid: episode.guid,
                    number: episode.data.episode_number,
                    status: episode.status,
                    type: 'episode'
                }
            },
            episodesData.push(episodeTreeNode)
            this.episodeContainers.push(episode)
        });
        seasonTreeNode.children = episodesData
    }

    onStatusSelectionChange($event) {
        this.status = $event.status;
        this.loadPage();
    }

    nodeSelect(event) {
        if (event.node.children && event.node.children.length > 0) {
            event.node.children.forEach(child => {
                let container = this.episodeContainers.find((container) => container.guid == child.data.guid);
                this.selectedEpisodes.push(container);
            });

        }
        else {
            let container = this.episodeContainers.find((container) => container.guid == event.node.data.guid);
            this.selectedEpisodes.push(container);
        }
    }

    nodeUnselect(event) {
        if (event.node.children && event.node.children.length > 0) {
            event.node.children.forEach(child => {

                let container = this.selectedEpisodes.find((container) => container.guid == child.data.guid);
                const index = this.selectedEpisodes.indexOf(container, 0);
                if (index > -1) {
                    this.selectedEpisodes.splice(index, 1);
                }
            });

        }
        else {
            let container = this.selectedEpisodes.find((container) => container.guid == event.node.data.guid);
            const index = this.selectedEpisodes.indexOf(container, 0);
            if (index > -1) {
                this.selectedEpisodes.splice(index, 1);
            }
        }
    }


    batchUpdatePublishDateAndPublish() {
        this.selectedEpisodes.forEach(episode => {
            episode.published_date = Math.trunc(Date.now() / 1000);
            episode.status = "published"
        })
        this.saveSelectedContainers(this.selectedEpisodes);
    }

    batchUnpublish() {
        this.selectedEpisodes.forEach(episode => {
            episode.status = "unpublished"
        })
        this.saveSelectedContainers(this.selectedEpisodes);
    }


    batchUpdatePublicWindowEndDateContainers() {
        this.selectedEpisodes.forEach(episode => {
            episode.data['public_window_end_date'] = this.convertDateFormat(this.publicWindowEndDate)
            episode.data['available_date'] = this.convertDateFormat(new Date(Date.now()))
        })
        this.saveSelectedContainers(this.selectedEpisodes);

        this.displayPublicWindowEndDateDialog = false
    }

    batchUpdateNullPublicWindowEndDateContainers() {
        this.selectedEpisodes.forEach(episode => {
            episode.data['public_window_end_date'] = ""
            episode.data['available_date'] = this.convertDateFormat(new Date(Date.now()))
        })
        this.saveSelectedContainers(this.selectedEpisodes);

    }

    batchLockContainers() {
        this.selectedEpisodes.forEach(episode => {
            episode.data['public_window_end_date'] = this.convertDateFormat(new Date(Date.now()))
        })
        this.saveSelectedContainers(this.selectedEpisodes);
    }

    convertDateFormat(value) {
        let dateString;
        if (value === null) {
            dateString = '1970-01-01T00:00:00+00:00';
        } else {
            dateString = value.toISOString().substr(0, 19) + '+00:00';
        }
        return dateString;
    }


    saveSelectedContainers(containers) {
        console.log('relate submit heard', containers);
        this.processing = true;
        this.progress = 0;
        this.saveContainersRecursive(containers, 0, () => {
            if (this.error) {
                alert("Completed, but an error was encountered when saving one or more items: " + this.error)
                this.processing = false;
                this.location.back();
            }
            else {
                setTimeout(() => {
                    alert("Batch update completed successfully.")
                    this.processing = false;
                    this.location.back();
                },500) //let progress bar finish
            }
        });
    }

    actionPublishSelected() {
        if (this.selectedEpisodes.length == 0){
            alert("Please select one or more containers.")
            return
        }
        this.batchUpdatePublishDateAndPublish()
    }

    actionUnlockSelected() {
        if (this.selectedEpisodes.length == 0){
            alert("Please select one or more containers.")
            return
        }
        this.displayPublicWindowEndDateDialog = true;
    }

    actionUnlockSelectedNoEndDate() {
        if (this.selectedEpisodes.length == 0){
            alert("Please select one or more containers.")
            return
        }
        this.batchUpdateNullPublicWindowEndDateContainers()
    }


    actionLockSelected() {
        if (this.selectedEpisodes.length == 0){
            alert("Please select one or more containers.")
            return
        }
        this.batchLockContainers()
    }

    actionUnpublishSelected() {
        if (this.selectedEpisodes.length == 0){
            alert("Please select one or more containers.")
            return
        }
        this.batchUnpublish();
    }

    saveContainersRecursive(containers, currentIndex, onDone) {
        this.containerService
            .save(containers[currentIndex])
            .subscribe(
                res => {
                    this.progress = ((currentIndex + 1) / containers.length) * 100;
                    if (currentIndex < containers.length - 1) {
                        this.saveContainersRecursive(containers, currentIndex + 1, onDone);
                    } else {
                        onDone();
                    }
                },
                err => {
                    this.error = 'Relationship failed or already exists';
                    this.progress = ((currentIndex + 1) / containers.length) * 100;
                    if (currentIndex < containers.length - 1) {
                        this.saveContainersRecursive(containers, currentIndex + 1, onDone);
                    } else {
                        onDone();
                    }
                }
            );
    }

    createMenuItems() {
        let statusActionMenuItems = []
        let publicWindowActionMenuItems = []
        let menuItem = { label: "Unlock (No Date)", command: () => this.actionUnlockSelectedNoEndDate() }
        publicWindowActionMenuItems.push(menuItem);

        menuItem = { label: "Unlock Selected", command: () => this.actionUnlockSelected() }
        publicWindowActionMenuItems.push(menuItem);

        menuItem = { label: "Lock Selected", command: () => this.actionLockSelected() }
        publicWindowActionMenuItems.push(menuItem);

        menuItem = { label: "Publish Selected", command: () => this.actionPublishSelected() }
        statusActionMenuItems.push(menuItem);

        menuItem = { label: "Unpublish Selected", command: () => this.actionUnpublishSelected() }
        statusActionMenuItems.push(menuItem);


        this.actionMenuItems = [
            {
                label: 'Status',
                items: statusActionMenuItems
            },
            {
                label: 'Availability',
                items: publicWindowActionMenuItems
            }
        ];
    }

    isSelectionDisabled(nodeData, rowData)
    {
        if ((rowData.type === 'season' && rowData.loading === true) || (nodeData.node.children && nodeData.node.children.length === 0))
        {
            return true;
        }
        return false;
    }
}
