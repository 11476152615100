import { Component, Inject, forwardRef } from '@angular/core';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AppContext } from './app.context';
import { ResourceUploadService } from '../app/upload/resource-upload.service';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../app/auth/auth.service'

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.css']
})
export class AppTopBarComponent {

    public showProfile = false;
    private changePasswordURL = environment.PASSWORD_CHANGE_URL;
    private maxMenuItemsPerColumn = 12;

    public searchQuery;

    constructor(
        public app: AppComponent,
        public appContext: AppContext,
        private router: Router,
        public resourceUploadService: ResourceUploadService,
        private confirmationService: ConfirmationService,
        private authService: AuthService
    ) {
        console.log(resourceUploadService);
    }

    openProfile() {
        this.showProfile = true;
    }

    changePassword()
    {
        window.location.href = this.changePasswordURL;
    }

    onSearch($event){
        console.log("Search Presssed", $event)

        this.appContext.componentState['search_view'] = {
            'type': null,
            'search': this.searchQuery,
            'status': null,
            'offset': 0,
            'selectedTags':[],
        };

        this.router.navigate(['/containers']);
    }

    logoutConfirm() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to log out?',
            accept: () => {
                console.log('confirm logout');
                this.authService.logout(true);
            }
        });
    }
    getMenuItemLayoutWidth()
    {
        let menuColumns = Math.ceil(this.appContext.authorizedOrigins.length / this.maxMenuItemsPerColumn)
        menuColumns = Math.min(menuColumns,4)
        let menuColumnsWidth = (menuColumns * 175);
        return menuColumnsWidth +'px'
    }
    getMenuItemLayoutClass()
    {
        let menuColumns = Math.ceil(this.appContext.authorizedOrigins.length / this.maxMenuItemsPerColumn)
        switch(menuColumns) { 
            case 1: { 
                return 'ui-g-12'
            } 
            case 2:{
                return 'ui-g-6'
            }
            case 3:{
                return 'ui-g-4'
            }
            default: { 
                return 'ui-g-3'
             } 
        }
    }
}
