<spinner *ngIf="isLoading"></spinner>
<div *ngIf="!isLoading" style="height:4px;"></div>
<p-toolbar>
    <div class="ui-g" >
        <div class="ui-g-3">
            <container-single-select-pulldown ngDefaultControl [options]="entityTypes" [allowOptions]="viewRestrictionsAllowedType" (onChangeSelection)="onChangedFilteredType($event)" placeholder="No Type Filter" optionsLabelKey="name" [ngModel]="selectedType" data-element-id="app-content-components-container-table-dropdown-type"></container-single-select-pulldown>
        </div>
        <div class="ui-g-3">
            <span class="p-input-icon-right" style="text-align:center;height:100%;width:100%;">
                <i class="pi pi-search" (click)="changedSearchTerm($event)"></i>
                <input style="text-align:center;height:100%;width:100%;" type="text" [(ngModel)]="searchTerm" (ngModelChange)="changedSearchTerm($event)" pInputText placeholder="Search" data-element-id="app-content-components-container-table-textbox-search">
            </span>
        </div>
        <div class="ui-g-3">
            <container-single-select-pulldown ngDefaultControl [options]="statusList" [allowOptions]="viewRestrictionsAllowedStatus" (onChangeSelection)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionsLabelKey="value" [ngModel]="selectedStatus" data-element-id="app-content-components-container-table-dropdown-status"></container-single-select-pulldown>
        </div>
        <div class="ui-g-3">
          <tag-select class="'p-multiselect-label'" [defaultTags]="selectedTags" (onTagSelect)="onChangedFilteredTag($event)" [allowTagCategories]="viewRestrictionsAllowedTagCategories" data-element-id="app-content-components-container-table-dropdown"></tag-select>
        </div>
    </div>
</p-toolbar>
<p-table
        [columns]="columns"
        [value]="containers"
        [rows]="20"
        [paginator]="true"
        [first]="offset"
        paginatorPosition="both"
        dataKey="guid"
        [lazy]="true"
        (onLazyLoad)="loadResultsLazy($event)"
        styleClass="p-datatable-gridlines"
        [totalRecords]="searchResult.count"
        [responsive]="true"
        data-element-id="app-content-components-container-table-textbox-search-results">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 2.25em"></th>
            <th *ngFor="let col of columns" [ngStyle]="col.style">
                {{col.header}}
            </th>
            <th style="max-width: fit-content; overflow:hidden">Published</th>
            <th style="width: 6em"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-container let-expanded="expanded" let-columns="columns">
        <tr *ngIf="!isLoading">
            <td>
                <a [pRowToggler]="container">
                    <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                </a>
            </td>
            <td *ngFor="let col of columns">
                <div [ngStyle]="col.style" [innerHTML]="getFieldData(container, col)"></div>
            </td>
            <td>
                <div style="max-width:fit-content; overflow:hidden">{{container.published_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}</div>
            </td>
            <td>

                <button *ngIf="container.type == 'media'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/media', container.id,'']" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-media-edit"></button>
                <button *ngIf="container.type == 'season'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/seasons', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-season-edit"></button>
                <button *ngIf="container.type == 'episode'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/episodes', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-episode-edit"></button>
                <button *ngIf="container.type == 'series'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/series', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-series-edit"></button>
                <button *ngIf="container.type == 'collection'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/collections', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-collection-edit"></button>
                <button *ngIf="container.type == 'rotator'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-rotator-edit"></button>
                <button *ngIf="container.type == 'channel'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/channels', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-channel-edit"></button>
                <button *ngIf="container.type == 'view'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/view', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-view-edit"></button>
                <button *ngIf="container.type == 'movie'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/movie', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-movie-edit"></button>
                <button *ngIf="container.type == 'document'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/document', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-document-edit"></button>
                <button *ngIf="container.type == 'person'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/persons', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-person-edit"></button>
                <button *ngIf="container.type == 'cast'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/cast', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-cast-edit"></button>

                <div *ngxPermissionsOnly="['view.show.commerce']">
                  <button *ngIf="container.type == 'advertisement'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/advertisement', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-advertisement-edit"></button>
                  <button *ngIf="container.type == 'campaign'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/campaign', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-campaign-edit"></button>
                  <button *ngIf="container.type == 'content_group'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/contentgroup', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-content-group-edit"></button>
                  <button *ngIf="container.type == 'moment'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/moment', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-moment-edit"></button>
                  <button *ngIf="container.type == 'flight'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/flights', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-flight-edit"></button>

                </div>

                <div *ngxPermissionsOnly="['view.show.settings']">
                  <button *ngIf="container.type == 'pipeline'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/pipelines', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-pipeline-edit"></button>
                  <button *ngIf="container.type == 'worker'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/workers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-worker-edit"></button>
                  <button *ngIf="container.type == 'server'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/server', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-server-edit"></button>
                  <button *ngIf="container.type == 'profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/profiles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-profile-edit"></button>
                  <button *ngIf="container.type == 'application'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/applications', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-applications-edit"></button>
                  <button *ngIf="container.type == 'catalog'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/catalog', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-catalog-edit"></button>
                  <button *ngIf="container.type == 'configuration'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/configurations', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-configuration-edit"></button>
                  <button *ngIf="container.type == 'distribution'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/distributions', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-distribution-edit"></button>
                  <button *ngIf="container.type == 'platform'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/platform', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-platform-edit"></button>
                  <button *ngIf="container.type == 'policy'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/policy', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-policy-edit"></button>
                  <button *ngIf="container.type == 'role'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/roles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-role-edit"></button>
                  <button *ngIf="container.type == 'version'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/versions', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-version-edit"></button>
                  <button *ngIf="container.type == 'origin'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/origins', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-origin-edit"></button>
                  <button *ngIf="container.type == 'provider'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/providers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-provider-edit"></button>
                  <button *ngIf="container.type == 'user_profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/user/profiles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-user-profile-edit"></button>
                  <button *ngIf="container.type == 'report'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/reports', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-report-edit"></button>
                  <button *ngIf="container.type == 'workflow'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/workflow', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-workflow-edit"></button>
                  <button *ngIf="container.type == 'creative'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/creative', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;" data-element-id="app-content-components-container-table-creative-edit"></button>
                  <button *ngIf="container.type == 'test'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/test', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'test_suite'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/settings/testsuite', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  
                  <button *ngIf="dedicatedEditForms.includes(container.type) ==  false" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <!--Add container.type to dedicatedEditForms array before creating new dedicated edit forms-->
                </div>

                <!-- <button *ngIf="(container.type != 'media') && (container.type != 'season') && (container.type != 'episode') && (container.type != 'series') && (container.type != 'collection') && (container.type != 'rotator') && (container.type != 'view')" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="fa fa-wrench" style="width:100%;font-size:13px;"></button>
                -->
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-container let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 3">
                <div class="ui-g p-fluid" style="font-size:14px;padding:0.2em">
                    <div class="ui-g-2" style="text-align:center">
                        <img style="max-height:8em;width:auto;max-width:100%" src="{{extractThumbnail(container)}}">
                    </div>
                    <div class="ui-g-4">
                        <div>Description</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container['data']['description']}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-4">
                        <div>Keywords</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container['data']['keywords']}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-2">
                        <div>
                            <strong>Created:</strong><br>
                            {{container.created_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                        <br>
                        <!-- <hr/> -->
                        <div>
                            <strong>Updated:</strong><br>
                            {{container.updated_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
