
<p-dialog header="Upload Media" [modal]="true" [(visible)]="displayQuickUploadWindow" [responsive]="true" styleClass="dialog-wide">
 <upload-resource-simple-form *ngIf=displayQuickUploadWindow (formFailed)="closeQuickUploadDialog()" (formCancelled)="closeQuickUploadDialog()" (formSubmit)="closeQuickUploadDialog()"></upload-resource-simple-form>
</p-dialog>

<p-dialog header="Upload Media" [modal]="true" [(visible)]="displayDetailedUploadWindow" [responsive]="true" styleClass="dialog-tall-wide">
 <upload-media-form *ngIf="displayDetailedUploadWindow" (formFailed)="closeDetailedUploadDialog()" (formCancelled)="closeDetailedUploadDialog()" (formSubmit)="closeDetailedUploadDialog()"></upload-media-form>
</p-dialog>

<div *ngIf="app.isAuthenticated()" class="layout-sidebar" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}"
    (click)="app.onSidebarClick($event)">

    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li [ngClass]="{'active-item':app.activeTabIndex === 0}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 0)" [routerLink]="['']" data-element-id="app-show-home">
                    <i class="fa fa-fw fa-home"></i>
                </a>
                <!-- <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Dashboard</div>
                </div> -->
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 8}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 8)" [routerLink]="['/containers']" data-element-id="app-show-home">
                    <i class="fa fa-fw fa-search"></i>
                </a>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 1}" *ngxPermissionsOnly="['view.show.content']">
                <a href="#" class="tabmenuitem-link" (click)="app.onTabClick($event, 1)" data-element-id="app-show-content">
                    <i class="fa fa-th-list"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Content</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 6}" *ngxPermissionsOnly="['view.show.upload']">
                    <a class="tabmenuitem-link" (click)="showQuickUploadDialog()">
                        <i class="fa fa-upload"></i>
                    </a>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 2}" *ngxPermissionsOnly="['view.show.curate']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 2)">
                    <i class="fa fa-bookmark"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Curate Content</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 7}" *ngxPermissionsOnly="['view.show.commerce']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 7)" data-element-id="app-show-dollar">
                    <i class="fa fa-dollar"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Commerce</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 4}" *ngxPermissionsOnly="['view.show.metrics', 'view.show.reports']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 4)" data-element-id="app-show-metrics">
                    <i class="fa fa-tachometer"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Metrics</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 5}" *ngxPermissionsOnly="['view.show.settings', 'view.show.tag']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 5)" data-element-id="app-show-setting">
                    <i class="fa fa-cogs"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Settings</div>
                </div>
            </li>
        </ul>
        <div class="layout-tabmenu-contents" data-element-id="app-layout-tabmenu-contents">
            <!-- <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 0}">
                <div class="layout-submenu-title clearfix">
                    <span>DASHBOARD</span>
                </div>
            </div> -->
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 1}">
                <app-sidebarTabContent>
                  <p-menu [model]="contentTabItems"></p-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 2}">
                <!-- different menu structure for a panel menu -->
                <app-sidebarTabContent *ngIf="!isLoadingCurate">
                    <div class="layout-submenu-title clearfix">
                        <span>CURATE</span>
                    </div>  
                    <p-panelMenu [model]="newItemMenu" [multiple]="false"> </p-panelMenu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 7}">
                <app-sidebarTabContent>
                    <p-menu [model]="commerceTabItems"></p-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 4}">
                <app-sidebarTabContent>
                <p-menu [model]="metricsTabItems"></p-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 5}">
                <app-sidebarTabContent>
                <p-menu [model]="configTabItems"></p-menu>
              </app-sidebarTabContent>
            </div>
        </div>
    </div>
</div>
